<template>
  <main class="point-report">
    <div style="margin-bottom: 24px">
      <BasicTabs v-model="activeTab" :tabs="tabsOptions" @change="onTabChange" />
    </div>

    <PageTitle title="點數報表" hideBtn />
    <YearStatistic :shopPointKey="activeTab" />
    <MemberPointBalance :shopPointKey="activeTab" />
    <PointRecordTable :shopPointKey="activeTab" />
  </main>
</template>

<script>
import PointRecordTable from './components/PointRecordTable.vue'
import MemberPointBalance from './components/MemberPointBalance.vue'
import YearStatistic from './components/YearStatistic.vue'
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { useShop } from '@/use/shop'
import { map, get } from 'lodash'

export default defineComponent({
  name: 'PointReport',
  components: { PointRecordTable, MemberPointBalance, YearStatistic },
  setup (props, { emit }) {
    const { shopPointList } = useShop()
    const activeTab = ref('')
    const dialog = reactive({
      exportOptions: false,
      exporting: false,
    })

    const tabsOptions = computed(() => {
      return map(shopPointList.value, (item) => {
        return {
          label: item.name,
          value: item.key,
        }
      })
    })

    const onTabChange = (newTab) => {
    }

    onMounted(() => {
      activeTab.value = get(tabsOptions.value, '[0].value')
    })

    return {
      dialog,
      tabsOptions,
      activeTab,
      onTabChange,
    }
  },
})

</script>
