<template>
  <div v-loading="loading" class="member-point-balance">
    <SectionTitle fontSize="18" btn="匯出" @edit="dialog.exportOptions = true">
      <template slot="title">
        <p style="font-size: 18px">會員點數餘額</p>
        <TipInfo>
          <p>每位會員可使用的剩餘點數加總</p>
        </TipInfo>
      </template>
    </SectionTitle>
    <FiltersContainer>
      <el-date-picker
        v-model="searchDate"
        format="yyyy-MM-dd"
        editable
        placeholder="選擇日期"
        @change="refresh"
      />
    </FiltersContainer>
    <PointStatisticBlock :data="displayData" />
    <ExportMemberBalanceDialog
      v-if="dialog.exportOptions"
      title="匯出會員點數餘額"
      :dailyMember="dailyMember"
      @close="dialog.exportOptions = false"
      @download="downloadExport"
    />
  </div>
</template>

<script setup>
import TipInfo from '@/components/TipInfo.vue'
import PointStatisticBlock from '@/components/Report/PointStatisticBlock.vue'
import ExportMemberBalanceDialog from './ExportMemberBalanceDialog.vue'
import {
  GetBusinessDataAutoSheetRecords,
  GetSumMemberBalance,
  DownloadBusinessDataAutoSheetRecord,
} from '@/api/shopPoint'
import store from '@/store'
import { computed, onMounted, ref, reactive, watch } from 'vue'
import { get, filter, sortBy } from 'lodash'
import { formatDate } from '@/utils/date'
import dayjs from 'dayjs'

const props = defineProps({
  shopPointKey: {
    type: String,
    required: true,
  },
})

const searchDate = ref(new Date())
const loading = ref(false)
const shopId = computed(() => store.getters.shop)
const dailyMember = ref(null)
const sumMemberBalance = ref(null)
const dialog = reactive({
  exportOptions: false,
})
const displayData = computed(() => {
  const daily = dailyMember.value
  let currentData = {}
  currentData = filter(daily, (item) => dayjs(item.exportedAt, 'day').isSame(dayjs(searchDate.value), 'day'))
  if (currentData.length > 1) {
    currentData = sortBy(currentData, item => dayjs(item.exportedAt)).reverse()
  }
  const target = currentData[0]
  const dailyBalance = get(target, 'exportSummary.sumBalance')
  const dailyUpdateTime = get(target, 'exportedAt') ? formatDate(get(target, 'exportedAt'), 'YYYY/MM/DD HH:mm:ss') : null
  const currentMember = get(sumMemberBalance, 'value')
  const currentUpdateTime = get(currentMember, 'queryAt') ? formatDate(get(currentMember, 'queryAt'), 'YYYY/MM/DD HH:mm:ss') : '-'
  return [
    { label: `每日會員點數餘額 ${dailyUpdateTime ? `（更新時間：${dailyUpdateTime})` : ''}`, value: dailyBalance },
    { label: `當前會員點數餘額（更新時間：${currentUpdateTime}）`, value: get(currentMember, 'sumBalance'), tooltip: '此欄位顯示數值每小時僅更新一次' },
  ]
})

const getSumMemberBalance = async () => {
  const [res, err] = await GetSumMemberBalance({
    shopId: shopId.value,
    shopPointKey: props.shopPointKey,
  })
  if (err) {
    window.$message.error(err)
    return
  }
  sumMemberBalance.value = res
}
const getBusinessDataAutoSheetRecords = async () => {
  const [res, err] = await GetBusinessDataAutoSheetRecords({
    shopId: shopId.value,
  })
  if (err) {
    window.$message.error(err)
    return
  }
  dailyMember.value = res
}
const downloadExport = async (data) => {
  console.log(data)
  const [res, err] = await DownloadBusinessDataAutoSheetRecord({
    shopId: shopId.value,
    businessDataAutoSheetRecordId: data.businessDataAutoSheetRecordId,
  })
  if (err) {
    window.$message.error(err)
    return
  }
  window.location.href = res.downloadLink
  dialog.exportOptions = false
  window.$message.success('匯出成功')
}
const refresh = async () => {
  loading.value = true
  await getBusinessDataAutoSheetRecords()
  await getSumMemberBalance()
  loading.value = false
}

watch(() => props.shopPointKey, async () => {
  if (!props.shopPointKey) return
  await refresh()
})

</script>

<style scoped lang="postcss">
.member-point-balance {
  @apply mt-[30px];
}
</style>
